<template>
  <div
    class="env-detail"
    :style="{ height: visibleWH.height + 'px' }"
  >
    <div
      class="env-detail-nav"
      v-loading="loading"
    >
      <div
        class="item"
        v-for="(item, index) in navData"
        :key="index"
        :class="{ active: currentActiveData.id === item.id }"
        @click="changeNav({ id: item.id, index })"
      >
        <ScaleImage>
          <PlaceholderImage
            :src="item.imgUrl"
            :width="800"
            :height="450"
            :alt="item.ch"
          />
          <div class="txts">
            <p class="ch">{{ item.ch }}</p>
            <p class="en">{{ item.en }}</p>
          </div>
        </ScaleImage>
      </div>
    </div>
    <div class="env-detail-cont">
      <div class="env-detail-banner">
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
        >
          <swiper-slide
            v-for="(item, index) in navData"
            :key="index"
          >
            <div
              class="pic"
              :style="{ 'background-image': `url(${item.imgUrl})`, color: '#fff' }"
            ></div>
          </swiper-slide>
        </swiper>

      </div>
      <div class="env-detail-main">
        <div class="env-detail-top">
          <div class="box">
            <transition
              name="up"
              mode="out-in"
            >
              <div v-if="showTitle">
                <p class="ch">{{ currentActiveData.ch }}</p>
                <p class="en">{{ currentActiveData.en }}</p>
              </div>
            </transition>
            <SJXPoint />
          </div>
        </div>
        <div class="env-detail-list">
          <div
            class="box"
            v-loading="detailLoading"
          >
            <p
              v-html="currentActiveData.desc"
              class="desc"
              v-if="currentActiveData.desc"
            ></p>
            <div v-if="detailData.length">
              <div
                class="item"
                v-for="(item, index) in detailData"
                :key="index"
              >
                <img
                  :src="item.imgUrl"
                  alt=""
                />
              </div>
            </div>
            <el-empty v-else />
          </div>
        </div>
      </div>
    </div>
    <div
      class="env-detail-close"
      @click="goBackList"
    ><img
        alt=""
        :src="require('@/assets/ShuJiuXiang/close@2x.png')"
      /></div>
  </div>
</template>

<script>
import { getEnv, getEnvDetail } from '@/api/ShuJiuXiang/brand';
import { Scale, Placeholder } from '@/components/Image';
import { mapState } from 'vuex';
import { SJXPoint } from '@/components/DownPoint';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
export default {
  components: {
    ScaleImage: Scale,
    PlaceholderImage: Placeholder,
    SJXPoint,
    swiperSlide,
    swiper,
  },
  data() {
    return {
      navData: [],
      loading: false,
      detailLoading: false,
      detailData: [],
      showTitle: false,
      timer: null,
      swiperOptions: {
        loop: false,
        speed: 1000,
        autoplay: false,
        allowTouchMove: false,
      },
    };
  },
  async mounted() {
    this.init();
    await this.getNavData();
    this.initialSlide();
  },
  computed: {
    ...mapState(['isMobile', 'isNearMobile', 'visibleWH']),
    currentActiveData() {
      const { id } = this.$route.params;
      return this.navData.find((d) => d.id === parseInt(id, 10)) || {};
    },
    swiper() {
      return this.$refs.mySwiper?.swiper;
    },
  },
  methods: {
    goBackList() {
      this.$router.go(-1);
    },
    initialSlide() {
      const { id } = this.$route.params;
      const index = this.navData.findIndex((d) => d.id === parseInt(id, 10)) || 0;
      this.swiper?.slideTo(index, 1000, false);
    },
    changeNav({ id, index }) {
      this.showTitle = false;
      this.$router.replace(`/sjx/brand/env/detail/${id}`);
      this.swiper?.slideTo(index, 1000, false);
    },
    async getNavData() {
      this.loading = true;
      try {
        const res = await getEnv();
        this.loading = false;
        this.navData = res.map((d) => ({
          id: d.storeId,
          title: d.storeTitle,
          imgUrl: d.storeImage,
          ch: d.storeTitle,
          en: d.storeViceTitle,
          desc: d.storeRemark,
        }));
      } catch (err) {
        this.loading = false;
      }
    },
    async getDetailData() {
      this.detailLoading = true;
      try {
        const { id } = this.$route.params;
        const res = await getEnvDetail({
          storeId: id,
        });
        this.detailLoading = false;
        this.detailData = res.map((d) => ({
          id: d.storeId,
          imgUrl: d.storeImageUrl,
        }));
      } catch (err) {
        this.detailLoading = false;
      }
    },
    init() {
      this.getDetailData();
      this.timer = setTimeout(() => {
        this.showTitle = true;
      }, 200);
    },
    destroyed() {
      clearTimeout(this.timer);
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.env-detail {
  display: flex;
  height: 100vh;
  background: #000;
  &-close {
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    z-index: 199;
    right: 40px;
    top: 20px;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  &-nav {
    width: 240px;
    overflow: hidden;
    overflow-y: auto;
    ::v-deep {
      .el-image__inner {
        filter: grayscale(100%);
        filter: gray;
      }
    }
    .item {
      position: relative;
      cursor: pointer;
      @media screen and (min-width: $hover-point) {
        &:hover {
          ::v-deep {
            .el-image__inner {
              filter: none;
            }
          }
          .txts {
            transform: none;
            @include show;
          }
        }
      }
      &.active {
        ::v-deep {
          .el-image__inner {
            filter: none;
          }
        }
        .txts {
          transform: none;
          @include show;
        }
      }
    }
    .txts {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      color: #fff;
      padding: 0 20px 15px;
      transform: translateY(10px);
      @include hide;
      transition: 300ms;
    }
    .ch {
      font-size: 16px;
      line-height: math.div(21, 16);
      @include text-nowrap;
      margin-bottom: 4px;
    }
    .en {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      line-height: math.div(16, 14);
      @include text-nowrap;
    }
  }
  &-cont {
    flex: 1;
    overflow: hidden;
    position: relative;
  }
  &-banner {
    height: 100%;
    ::v-deep {
      .swiper-container {
        height: 100%;
      }
    }
    .pic {
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &-main {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    z-index: 99;
  }
  &-top {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: url('../../../assets/ShuJiuXiang/env-detail-bg.png') no-repeat center;
    background-size: cover;
    color: #fff;
    font-size: 48px;
    .box {
      padding-bottom: 25px;
    }
    .ch {
      line-height: math.div(64, 48);
      @include text-nowrap;
    }
    .en {
      @include text-nowrap;
      line-height: math.div(53, 48);
      font-weight: bold;
      margin-top: 8px;
    }
    ::v-deep {
      .SJXPoint {
        position: static;
        margin-top: 75px;
        cursor: default;
        > img {
          display: block;
        }
      }
    }
  }
  &-list {
    padding: 50px 0 100px;
    background: rgba(0, 0, 0, 0.8);
    ::v-deep {
      .el-loading-mask {
        background: rgba(0, 0, 0, 0.5);
      }
    }
    .desc {
      color: #fff;
      margin-bottom: 30px;
    }
    .item {
      text-align: center;
      > img {
        vertical-align: bottom;
        max-width: 100%;
      }
      + .item {
        margin-top: 30px;
      }
    }
  }
  @media screen and (max-width: $res-point-3) {
    &-top {
      font-size: 48px * 0.8;
    }
  }
  @media screen and (max-width: $res-point-6) {
    &-nav {
      width: 240px * 0.8;
      .txts {
        padding: 0 20px * 0.8 * 0.8 15px * 0.8 * 0.8;
      }
      .ch {
        font-size: 14px;
      }
      .en {
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: $res-point-8) {
    &-nav {
      width: 140px;
      .txts {
        display: none;
      }
    }
    &-top {
      font-size: 48px * 0.8 * 0.8;
    }
  }
  @media screen and (max-width: $res-point-9) {
    &-nav {
      width: 80px;
    }
    &-top {
      font-size: 48px * 0.8 * 0.8 * 0.8;
    }
    &-close {
      width: 44px;
      height: 44px;
      right: 5%;
      margin-top: 5%;
      top: 0;
      > img {
        transform: scale(0.7);
      }
    }
    &-list {
      .item {
        + .item {
          margin-top: 15px;
        }
      }
    }
  }
}
.box {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
}
.up-enter {
  transform: translateY(50%);
  opacity: 0;
  visibility: hidden;
}
.up-leave {
  opacity: 0;
}
.up-leave-active {
  transition: 300ms;
}
.up-enter-active {
  transition: 800ms;
}
</style>